:root {
  --version: 1.39;
}

.bs-bluebig {
  box-shadow: 15px 30px 40px rgba(1, 59, 107, 0.2), 4px -1px 60px rgba(1, 59, 107, 0.04);
}

.bs-blue {
  box-shadow: 2px 6px 30px rgba(6, 52, 98, 0.12);
}

.bs-sand {
  box-shadow: 10px 14px 30px rgba(238, 215, 198, 0.3), -2px -4px 20px rgba(238, 215, 198, 0.3);
}

.bs-toggle {
  box-shadow: 0 2px 4px rgba(50, 50, 71, 0.06), 0 2px 2px rgba(50, 50, 71, 0.06);
}

.bs-card {
  box-shadow: 2px 2px 8px rgba(6, 52, 98, 0.1);
  -webkit-transition: box-shadow 300ms ease-out 0s;
  transition: box-shadow 300ms ease-out 0s;
}
.bs-card:hover {
  box-shadow: 4px 8px 8px rgba(6, 52, 98, 0.1);
}

.bs-square {
  box-shadow: 0 0 16px 8px rgba(6, 52, 98, 0.0588235294);
}

.bg-rainbow {
  background: -webkit-gradient(linear, left top, right top, from(rgb(255, 0, 0)), color-stop(10%, rgb(255, 154, 0)), color-stop(20%, rgb(208, 222, 33)), color-stop(30%, rgb(79, 220, 74)), color-stop(40%, rgb(63, 218, 216)), color-stop(50%, rgb(47, 201, 226)), color-stop(60%, rgb(28, 127, 238)), color-stop(70%, rgb(95, 21, 242)), color-stop(80%, rgb(186, 12, 248)), color-stop(90%, rgb(251, 7, 217)), to(rgb(255, 0, 0)));
  background: linear-gradient(90deg, rgb(255, 0, 0) 0%, rgb(255, 154, 0) 10%, rgb(208, 222, 33) 20%, rgb(79, 220, 74) 30%, rgb(63, 218, 216) 40%, rgb(47, 201, 226) 50%, rgb(28, 127, 238) 60%, rgb(95, 21, 242) 70%, rgb(186, 12, 248) 80%, rgb(251, 7, 217) 90%, rgb(255, 0, 0) 100%);
}

.btn-close-primary {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23063462%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-secondary {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ff3944%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-black {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23253543%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-white {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-moussaillon {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2383c5ff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-marin {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffc839%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-matelot {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%233e9187%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-capitaine {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%237839ff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-steelblue {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%233e6a91%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-skyblue {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23e9f4fa%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-waterblue {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23dcecf5%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-midnightblue {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23042342%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-lightcoral {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ff7a82%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-sand {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fef9f5%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-sand2 {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffefe3%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-grey1 {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23a7afc3%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-grey2 {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23cdd6e0%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-grey3 {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23e5e7ed%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-grey4 {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23edeef2%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-grey5 {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23f8f8fa%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-success {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%233e9187%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-info {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2383c5ff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-warning {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffc839%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close-danger {
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ff3944%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.hover-bg-skyblue:hover {
  background-color: rgba(var(--bs-skyblue-rgb), var(--bs-bg-opacity)) !important;
}

body,
.font-body {
  font-size: 1rem;
}

.font-content {
  font-size: 1rem;
  line-height: 1.5;
}

.font-subtitle {
  font-size: 1rem;
}

@media (min-width: 768px) {
  .font-subtitle {
    font-size: 1.0625rem;
  }
}
@media (min-width: 992px) {
  .font-subtitle {
    font-size: 1.125rem;
  }
}
.col-form-label.font-h1,
.form-label.font-h1 {
  font-size: -webkit-calc(1.3rem + 0.7258064516vw) !important;
  font-size: calc(1.3rem + 0.7258064516vw) !important;
}
@media (min-width: 992px) {
  .col-form-label.font-h1,
  .form-label.font-h1 {
    font-size: 1.75rem !important;
  }
}

.col-form-label.font-h2,
.form-label.font-h2 {
  font-size: -webkit-calc(1.275rem + 0.3629032258vw) !important;
  font-size: calc(1.275rem + 0.3629032258vw) !important;
}
@media (min-width: 992px) {
  .col-form-label.font-h2,
  .form-label.font-h2 {
    font-size: 1.5rem !important;
  }
}

.col-form-label.font-h3,
.form-label.font-h3 {
  font-size: 1.25rem !important;
}

.col-form-label.font-h4,
.form-label.font-h4 {
  font-size: 1rem !important;
}

.col-form-label.font-h5,
.form-label.font-h5 {
  font-size: 1rem !important;
}

.col-form-label.font-h6,
.form-label.font-h6 {
  font-size: 1rem !important;
}

span.emphasis {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
[class*=font-h] {
  font-weight: 600 !important;
}
h1 span.emphasis,
h2 span.emphasis,
h3 span.emphasis,
h4 span.emphasis,
h5 span.emphasis,
h6 span.emphasis,
[class*=font-h] span.emphasis {
  color: #ff3944;
  font-style: normal;
  font-weight: 400 !important;
  font-family: "PraterScript", serif;
  font-size: 83%;
}

@media (max-width: 575.98px) {
  .text-white span.emphasis {
    color: #ffffff;
  }
}
[class*=font-h][class*=-prater] {
  font-family: "PraterScript", serif;
  font-weight: 400 !important;
}

.reduce-title-font-size h1,
.reduce-title-font-size h2,
.reduce-title-font-size h3,
.reduce-title-font-size h4,
.reduce-title-font-size h5,
.reduce-title-font-size h6,
.reduce-title-font-size [class*=font-h] {
  font-size: 100% !important;
  line-height: 100% !important;
}
.reduce-title-font-size br + br {
  display: none;
}

.font-minimal {
  font-size: 0.875rem;
}

.p,
.font-p,
.font-category,
.font-tag,
.font-minimal-info {
  font-size: 0.875rem;
}

.font-tab,
.font-list {
  font-size: 1.125rem;
}

.button-label,
.font-user,
.p-huge {
  font-size: 1rem;
}

.price {
  font-size: -webkit-calc(1.2875rem + 0.5443548387vw);
  font-size: calc(1.2875rem + 0.5443548387vw);
  color: #ff3944;
}
@media (min-width: 992px) {
  .price {
    font-size: 1.625rem;
  }
}

.font-user,
.font-category,
.font-tag {
  font-weight: 600;
}

.font-category {
  color: #ff3944;
}

.font-minimal-info,
.font-footer-info {
  color: #a7afc3;
}

.font-footer-info {
  font-size: 0.75rem;
}

.strong-is-secondary strong {
  color: #ff3944 !important;
}
.strong-is-secondary.fw-semibold strong {
  font-weight: 700 !important;
}

.links-underline a[href],
.alert a[href],
p a[href] {
  text-decoration: underline !important;
}
.links-underline a[href].btn:not(.btn-link),
.alert a[href].btn:not(.btn-link),
p a[href].btn:not(.btn-link) {
  text-decoration: none !important;
}

.rotate--2 {
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
}

.transition-hover {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.outline-icon-price {
  right: 10px;
  top: -12px;
}

@media (min-width: 992px) {
  .even-odd-inversed-container:nth-child(odd) .even-odd-inversed-element:first-child {
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .even-odd-inversed-container:nth-child(odd) .even-odd-inversed-element:last-child {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .even-odd-inversed-container:nth-child(even) .even-odd-inversed-element:first-child {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .even-odd-inversed-container:nth-child(even) .even-odd-inversed-:last-child {
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
}
@media (min-width: 768px) {
  .md-border-l-grey2 {
    border-left: 1px solid #cdd6e0;
  }
  .md-border-none {
    border: none !important;
  }
}
